import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import PageLayout from './PageLayout';

const PageLayoutWrapper = ({
  children,
}) => (
  <StaticQuery
    query={ graphql`
      query PageLayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    ` }
    render={ data => (
      <PageLayout data={ data }>
        { children }
      </PageLayout>
    ) }
  />
);

PageLayoutWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default PageLayoutWrapper;
