import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { PRIMARY_META_TAGS } from '../../constants/meta';

import Footer from '../Footer';
import FrameFooter from '../FrameFooter';
import Header from '../Header';

import '../../styles/index.css';
import styles from './index.module.css';

class PageLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (!!window.Intercom && window.Intercom.booted) {
        window.Intercom('shutdown');

        clearInterval(interval);
      }
    }, 100);
  }

  render() {
    const {
      children,
      data,
    } = this.props;

    return (
      <div className={ styles.wrapper }>
        <Helmet
          html={ { lang: 'en' } }
          title={ data.site.siteMetadata.title }
          meta={ PRIMARY_META_TAGS }
        />
        <Header hideBoxShadow />
        <div>
          {children}
        </div>
        <FrameFooter />
        <Footer />
      </div>
    );
  }
}

export default PageLayout;
