import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Home from './Home';

const HomeWrapper = props => (
  <StaticQuery
    query={ graphql`
      query {
        hero: file(relativePath: { eq: "home-hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        blurBlob: file(relativePath: { eq: "blur-blob.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    ` }
    render={ data => (
      <Home
        data={ data }
        { ...props }
      />
    ) }
  />
);

export default HomeWrapper;
