import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import compose from 'recompose/compose';

import Btn from '../Btn';
import withWindowListener from '../withWindowListener';

import styles from './index.module.css';

class Home extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isLarge: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    window.analytics.track('wfg-viewed');
  }

  render() {
    const {
      data,
      isLarge,
    } = this.props;

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.inner }>
          <div className={ styles.content }>
            <h1 className={ styles.title }>
              <span>
                We wrote the book on
              </span>
              <span className={ styles.titleGradient }>
                video workflows.
              </span>
            </h1>

            <p className={ styles.description }>
              Explore 100,000 words on everything you need to know, from capture to delivery,
              with contributions from the world’s most brilliant post-production minds.
            </p>

            <div className={ styles.ctaWrapper }>
              <Btn
                href='/guide'
                isLink
                label='Start Reading'
                onClick={ this.handleGuideClick }
              />
            </div>
          </div>
          { isLarge ?
            <div className={ styles.imgWrapper }>
              <Img className={ styles.img } fluid={ data.hero.childImageSharp.fluid } />
            </div> :
            <div className={ styles.blurBlobWrapper }>
              <div className={ styles.blurBlobInner }>
                <Img
                  className={ styles.blurBlob1 }
                  fluid={ data.blurBlob.childImageSharp.fluid }
                  style={ { position: 'absolute' } }
                />
                <Img
                  className={ styles.blurBlob2 }
                  fluid={ data.blurBlob.childImageSharp.fluid }
                  style={ { position: 'absolute' } }
                />
                <Img
                  className={ styles.blurBlob3 }
                  fluid={ data.blurBlob.childImageSharp.fluid }
                  style={ { position: 'absolute' } }
                />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  handleGuideClick = evt => {
    evt.preventDefault();

    const clickInfo = {
      client: 'wfg',
      page: 'home',
      position: 'middle',
      title: 'start reading',
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    navigate('/guide');
  }
}

export default compose(
  withWindowListener,
)(Home);
