import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Contributions from './Contributions';

const ContributionsWrapper = props => (
  <StaticQuery
    query={ graphql`
      query {
        adobe: file(relativePath: { eq: "contributor-logos-transparent/AdobeWhite.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lumaForge: file(relativePath: { eq: "contributor-logos-transparent/LumaWhite.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        warnerBros: file(relativePath: { eq: "contributor-logos-transparent/WBWhite.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        colorGradingCentral: file(relativePath: { eq: "contributor-logos-transparent/ColorGradingWhite.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        assimilate: file(relativePath: { eq: "contributor-logos-transparent/AssimilateWhite.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    ` }
    render={ data => (
      <Contributions
        data={ data }
        { ...props }
      />
    ) }
  />
);

export default ContributionsWrapper;
