import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './index.module.css';

const Contributions = ({
  data,
}) => (
  <div className={ styles.wrapper }>
    <div className={ styles.inner }>
      <h3 className={ styles.title }>
        Including Contributions From
      </h3>
      <ul className={ styles.list }>
        <li className={ styles.listItem }>
          <Img
            className={ styles.img }
            fluid={ data.adobe.childImageSharp.fluid }
          />
        </li>
        <li className={ styles.listItem }>
          <Img
            className={ styles.img }
            fluid={ data.lumaForge.childImageSharp.fluid }
          />
        </li>
        <li className={ styles.listItem }>
          <Img
            className={ styles.img }
            fluid={ data.warnerBros.childImageSharp.fluid }
          />
        </li>
        <li className={ styles.listItem }>
          <Img
            className={ styles.img }
            fluid={ data.colorGradingCentral.childImageSharp.fluid }
          />
        </li>
        <li className={ styles.listItem }>
          <Img
            className={ styles.img }
            fluid={ data.assimilate.childImageSharp.fluid }
          />
        </li>
      </ul>
    </div>
  </div>
);

Contributions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contributions;
