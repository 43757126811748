import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import cx from 'classnames';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';

import Btn from '../../Btn';
import withScrollListener from '../../withScrollListener';
import withWindowListener from '../../withWindowListener';

import styles from './index.module.css';

class PreviewChapter extends Component {
  static propTypes = {
    blurBlob: PropTypes.object.isRequired,
    chapter_meta: PropTypes.shape({
      chapter_subtitle: PropTypes.string.isRequired,
      chapter_read_time: PropTypes.string.isRequired,
    }),
    index: PropTypes.number.isRequired,
    isLarge: PropTypes.bool.isRequired,
    largeHeroImg: PropTypes.object,
    scroll: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    smallHeroImg: PropTypes.object,
    title: PropTypes.string.isRequired,
  }

  render() {
    const {
      blurBlob,
      chapter_meta: {
        chapter_subtitle,
        chapter_read_time,
      },
      index,
      isLarge,
      largeHeroImg,
      slug,
      smallHeroImg,
      title,
    } = this.props;

    const chapterNumber = index + 1;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.odd]: index % 2 === 1,
    });

    const chapterImgStyles = this.getChapterImgStyles();

    return (
      <div className={ wrapperClasses } ref={ r => { this.wrapperEl = r; } }>
        { isLarge &&
          <Img
            className={ styles.blurBlob1 }
            fluid={ blurBlob.childImageSharp.fluid }
            style={ this.getBlurBlobStyles(1) }
          />
        }
        { isLarge &&
          <Img
            className={ styles.blurBlob2 }
            fluid={ blurBlob.childImageSharp.fluid }
            style={ this.getBlurBlobStyles(2) }
          />
        }
        <div className={ styles.hero }>
          <div className={ styles.chapterNumber }>
            { chapterNumber < 10 ? `0${ chapterNumber }` : chapterNumber }
          </div>
          { isLarge ?
            largeHeroImg.node.childImageSharp &&
              <div className={ styles.chapterImgWrapper } style={ chapterImgStyles }>
                <Img
                  className={ styles.chapterImg }
                  fluid={ largeHeroImg.node.childImageSharp.fluid }
                />
              </div> :
            smallHeroImg.node.childImageSharp &&
              <div className={ styles.chapterImgWrapper } style={ chapterImgStyles }>
                <Img
                  className={ styles.chapterImg }
                  fluid={ smallHeroImg.node.childImageSharp.fluid }
                />
              </div>
          }

        </div>
        <div className={ styles.contentWrapper }>
          <div className={ styles.content }>
            <h2 className={ styles.title }>
              { title }
            </h2>
            <p className={ styles.description }>
              { chapter_subtitle }
            </p>
            <div className={ styles.readTime }>
              { chapter_read_time }
            </div>
            <div className={ styles.btnWrapper }>
              <Btn
                href={ `/guide#ch=${ slug }` }
                isLink
                label='Read now'
                onClick={ evt => this.handleCTAClick(evt, slug) }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getChapterImgStyles = () => {
    const {
      index,
      scroll,
    } = this.props;

    const isOdd = index % 2 === 1;

    const chapterImgStyles = {};

    if (this.wrapperEl) {
      const diff = scroll - this.wrapperEl.offsetTop;
      const margin = (diff + (window.innerHeight / 2)) * -0.11;

      const modifier = isOdd ? -0.005 : 0.006;
      const rotate = diff * modifier;

      chapterImgStyles.marginTop = `${ margin }px`;
      chapterImgStyles.transform = `rotate(${ rotate }deg)`;
    }

    return chapterImgStyles;
  }

  getBlurBlobStyles = num => {
    const {
      index,
      scroll,
    } = this.props;

    const isOdd = index % 2 === 1;

    const blurBlobStyles = { position: 'absolute' };

    if (this.wrapperEl) {
      let modifier = 0;
      if (isOdd) {
        modifier = num === 1 ? -0.05 : -0.14;
      } else {
        modifier = num === 1 ? -0.16 : -0.01;
      }

      const diff = scroll - this.wrapperEl.offsetTop;
      const translate = diff * modifier;

      blurBlobStyles.transform = `translateY(${ translate }%)`;
    }

    return blurBlobStyles;
  }

  handleCTAClick = (evt, slug) => {
    evt.preventDefault();

    const clickInfo = {
      client: 'wfg',
      page: 'home',
      position: 'bottom',
      title: `read now: ${ slug }`,
    };

    window.analytics.track('button-clicked', clickInfo);
    window.analytics.track('wfg-button-clicked', clickInfo);

    navigate(`/guide#ch=${ slug }`);
  }
}

export default compose(
  withScrollListener,
  withWindowListener,
)(PreviewChapter);
