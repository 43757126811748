import React from 'react';

import Contributions from '../components/Contributions';
import GuideTeaser from '../components/GuideTeaser';
import Home from '../components/Home';
import PageLayout from '../components/PageLayout';
import ChaptersOverview from '../components/ChaptersOverview';

const IndexPage = () => (
  <PageLayout>
    <Home />
    <Contributions />
    <GuideTeaser type='home-subscribe' slug='home' />
    <ChaptersOverview />
  </PageLayout>
);

export default IndexPage;
