import React from 'react';
import PropTypes from 'prop-types';

import PreviewChapter from './PreviewChapter';

import styles from './index.module.css';

const ChaptersOverview = ({
  data,
}) => (
  <div className={ styles.wrapper }>
    <div className={ styles.inner }>
      { data.allWordpressPage.edges.map((page, i) => {
        // Hacky way to hide Example Page - remove later
        if (page.node.slug === 'example-page') {
          return null;
        }

        const largeHeroImg = data.largeHeroImgs.edges[i] || { node: {} };
        const smallHeroImg = data.smallHeroImgs.edges[i] || { node: {} };

        return (
          <PreviewChapter
            { ...page.node }
            blurBlob={ data.blurBlob }
            key={ page.node.id }
            largeHeroImg={ largeHeroImg }
            smallHeroImg={ smallHeroImg }
            index={ i }
          />
        );
      }) }
    </div>
  </div>
);

ChaptersOverview.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChaptersOverview;
