import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import ChaptersOverview from './ChaptersOverview';

const ChaptersOverviewWrapper = props => (
  <StaticQuery
    query={ graphql`
      query {
        allWordpressPage(
          sort:{ fields: menu_order }
        ) {
          edges {
            node {
              id
              slug
              title
              chapter_meta {
                chapter_subtitle
                chapter_read_time
              }
            }
          }
        }
        largeHeroImgs: allFile(
          filter:{ relativeDirectory: {eq: "table-of-contents/square"}}
          sort:{ fields: [name], order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        smallHeroImgs: allFile(
          filter:{ relativeDirectory: {eq: "table-of-contents/dynamic"}}
          sort:{ fields: [name], order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        blurBlob: file(relativePath: { eq: "blur-blob.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    ` }
    render={ data => (
      <ChaptersOverview
        data={ data }
        { ...props }
      />
    ) }
  />
);

export default ChaptersOverviewWrapper;
